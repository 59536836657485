@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-montserrat{
  font-family: 'Montserrat', sans-serif!important;
}

.login-card{
  width: 1000px;
  margin: auto;
  margin-top: calc((100vh - 500px)/2) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.login-heading{
  line-height: 1.5!important;
  -webkit-font-smoothing: antialiased;
  font-weight: 700!important;
  margin-bottom: 40px!important;
}

.login-amber-img{
  margin-right: 60px;
  width: 300px;
}

.login-slack-div{
  margin: 0px!important;
  padding: 0px!important;
}

.text-centre{
  text-align: center;
}

@media (max-width: 1000px) {
  .login-card{
    width: 700px;
  }


}
@media (max-width: 767px) {
  .login-card{
    width: auto;
    margin: 20px;
    margin-top: 20px!important;
  }
  .login-amber-img{
    margin-right: 0px;
}

}
  @media (min-width: 767px) {
    .display-flex-tablet{
      display: flex;
    }
}


.center-align-div-content{
  display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-direction-column{
  display: flex;
  flex-direction: column;
}
:root{
  --color-primary:#eb2f64;
  --color-primary-light:#FF3366;
  --color-primary-dark:#BA265D;	
  --color-grey-light-1: #faf9f9;
 --color-grey-light-2: #f4f2f2;
 --color-grey-light-3: #f0eeee;
 --color-grey-light-4: #ccc;
 --color-grey-dark-1: #333;
 --color-grey-dark-2: #777;
 --color-grey-dark-3: #999;
 --shadow-dark:0 2rem 6rem rgba(0,0,0,.3);
}
/* $bp-largest:75em; 
$bp-large:68.75em; 
$bp-medium:56.25em;*/

*{
  margin:0;
  padding: 0;	
  }
  *,
  *::before,
  *::after{
    box-sizing:inherit;
  }
  html{
    box-sizing:border-box;
    /* font-size:62.5%;  
    @media only screen and (max-width: 68.75em) {
      font-size: 50%;
    } */
  }
  body{
    font-family: 'Open Sans',sans-serif;
    font-weight: 400;
    line-height:1.6;
    /* min-height: 100vh; */
  }
  a{
    text-decoration: none;
  }
