@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-montserrat{
  font-family: 'Montserrat', sans-serif!important;
}

.login-card{
  width: 1000px;
  margin: auto;
  margin-top: calc((100vh - 500px)/2) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.login-heading{
  line-height: 1.5!important;
  -webkit-font-smoothing: antialiased;
  font-weight: 700!important;
  margin-bottom: 40px!important;
}

.login-amber-img{
  margin-right: 60px;
  width: 300px;
}

.login-slack-div{
  margin: 0px!important;
  padding: 0px!important;
}

.text-centre{
  text-align: center;
}

@media (max-width: 1000px) {
  .login-card{
    width: 700px;
  }


}
@media (max-width: 767px) {
  .login-card{
    width: auto;
    margin: 20px;
    margin-top: 20px!important;
  }
  .login-amber-img{
    margin-right: 0px;
}

}
  @media (min-width: 767px) {
    .display-flex-tablet{
      display: flex;
    }
}


.center-align-div-content{
  display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-direction-column{
  display: flex;
  flex-direction: column;
}